import React from 'react';
import Box from "@mui/material/Box";
import {Button, IconButton, Typography, useMediaQuery, Divider} from "@mui/material";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from 'moment';
import ReceiptTop from "../../assets/images/Backgrounds/receipt_top.png"
import ReceiptBottom from "../../assets/images/Backgrounds/receipt_bottom.png"
import Amount from "../../assets/icons/amount.png"
import Clock from "../../assets/icons/clock.png"
import List from "../../assets/icons/list.png"
import Location from "../../assets/icons/location.png"
import ParkIn from "../../assets/icons/park_in.png"
import ParkOut from "../../assets/icons/park_out.png"
import Percentage from "../../assets/icons/percantage.png"
import Plate from "../../assets/icons/plate.png"
import Rate from "../../assets/icons/rate.png"


function CurrentParking(props) {

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'), {
    defaultMatches: true,
    noSsr: false
  });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: smDown ? '100%' : 600,
      backgroundColor: '#fff',
      // height: '100%'
    }}>
      <Box sx={{display: 'flex', backgroundColor: 'primary.main', width: '100%'}}>
        <IconButton
          color="inherit"
          align={"right"}
          onClick={props.back}
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>
      {/* <Typography variant='subtitle1' align='left' sx={{color: 'primary.main', width: '100%'}} >
        <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center'}}>
          <img src={ReceiptTop} width={'100%'}/>
        </Box>
      </Typography> */}
      <Box sx={{display: 'flex', width: '80%', marginTop: 1, justifyContent: 'center', alignItems: 'flex-end', color: 'black'}}>
        <Typography variant='subtitle1' align='left' sx={{color: 'primary.main'}} >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {props.parkings.map((x,index)=>{
              return(
                <Button 
                  size="small" 
                  variant={(x.parking_id==props.selectedList?.parking_id) ? 'outlined': ''} 
                  onClick={()=> props.seletecPlate(x)}>
                    {x.plate}
                </Button>
              )
            })}
          </Box>
        </Typography>
      </Box>
      <Divider sx={{width: '80%', mt: 1}}/>
      <Box sx={{display: 'flex', width: '80%', marginTop: 1, justifyContent: 'space-between', alignItems: 'center', color: 'black', mt: 2}}>
        <Typography variant='subtitle1' align='left' sx={{color: 'primary.main'}} >
          <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>
            <img src={List} width={'40px'}/>
            Parking ID
          </Box>
        </Typography>
        <Typography variant='subtitle1' align='left' sx={{color: 'primary.main'}} >
          <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
            {props.selectedList?.parking_id}
          </Box>
        </Typography>
      </Box>
      <Box sx={{textAlign: '-webkit-center', width: '100%'}}>
        <Box sx={{display: 'flex', width: '80%', marginTop: 1, justifyContent: 'space-between', alignItems: 'flex-end', color: 'black'}}>
          <Typography variant='subtitle1' align='left' sx={{color: 'primary.main', textAlign: 'center'}} >
            <img src={ParkIn} width={'50px'}/>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {moment(props.selectedList?.from).format('ll')}
            </Box>
            <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center', placeContent: 'center' }}>
              {moment(props.selectedList?.from).format('hh:mm a')}
            </Box>
          </Typography>
          <Typography variant='subtitle1' align='left' sx={{color: 'primary.main'}} >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={Clock} width={'80px'}/>
            </Box>
            <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center', placeContent: 'center' }}>
              {props.Countdown}
            </Box>
          </Typography>
          <Typography variant='subtitle1' align='left' sx={{color: 'primary.main', textAlign: 'center'}} >
            <img src={ParkOut} width={'50px'}/>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {moment(props.selectedList?.to).format('ll')}
            </Box>
            <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center', placeContent: 'center' }}>
              {moment(props.selectedList?.to).format('hh:mm a')}
            </Box>
          </Typography>
        </Box>
        <Divider sx={{width: '80%', mt:2}}/>
        <Box sx={{display: 'flex', width: '80%', marginTop: 1, justifyContent: 'space-between', alignItems: 'flex-end', color: 'black'}}>
          <Typography variant='subtitle1' align='left' sx={{color: 'primary.main'}} >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={Location} width={'40px'}/>
              {props.selectedList?.zone?.zone_name}, {props.selectedList?.city?.city_name}
            </Box>
          </Typography>
        </Box>
        <Divider sx={{width: '80%'}}/>
        <Box sx={{display: 'flex', width: '80%', marginTop: 1, justifyContent: 'space-between', alignItems: 'flex-end', color: 'black'}}>
          <Typography variant='subtitle1' align='left' sx={{color: 'primary.main'}} >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={Plate} width={'40px'}/>
              {props.selectedList?.plate}
            </Box>
          </Typography>
        </Box>
        <Divider sx={{width: '80%'}}/>
        <Box sx={{display: 'flex', width: '80%', marginTop: 1, justifyContent: 'space-between', alignItems: 'flex-end', color: 'black'}}>
          <Typography variant='subtitle1' align='left' sx={{color: 'primary.main'}} >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={Rate} width={'40px'}/>
              {props.selectedList?.rate?.rate_name}
            </Box>
          </Typography>
        </Box>
        <Divider sx={{width: '80%'}}/>
        <Box sx={{display: 'flex', width: '80%', marginTop: 1, justifyContent: 'space-between', alignItems: 'flex-end', color: 'black'}}>
          <Typography variant='subtitle1' align='left' sx={{color: 'primary.main'}} >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={Percentage} width={'40px'}/>
              Service Fee
            </Box>
          </Typography>
          <Typography variant='subtitle1' align='left' sx={{color: 'primary.main', alignSelf: 'center'}} >
            <Box>
              {(props.selectedList?.service_fee/100).toFixed(2)} $
            </Box>
          </Typography>
        </Box>
        <Divider sx={{width: '80%', mb: 2}}/>
        <Box sx={{display: 'flex', width: '80%', marginTop: 1, justifyContent: 'space-between', alignItems: 'flex-end', color: 'black'}}>
          <Typography variant='subtitle1' align='left' sx={{color: 'primary.main'}} >
            <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>
              <img src={Amount} width={'40px'}/>
              Amount Paid
            </Box>
          </Typography>
          <Typography variant='subtitle1' align='left' sx={{color: 'primary.main', alignSelf: 'center'}} >
            <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
              {(props.selectedList.amount/100).toFixed(2)} $
            </Box>
          </Typography>
        </Box>
      </Box>
      {/* <Box sx={{width: '100%'}}>
        <Box sx={{ fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>
          <img src={ReceiptBottom} width={'100%'}/>
        </Box>
      </Box> */}
    </Box>
  );
}

export default CurrentParking;